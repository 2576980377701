import request from '@/utils/requestV2'
const qs = require('qs')

// 新增开票
export function addInvoice (data) {
  return request({
    url: '/ooh-scp/v1/invoice/addinvoice',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 新增开票(批量)
export function addinvoices (data) {
  return request({
    url: '/ooh-scp/v1/invoice/addinvoices',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除某次开票
export function deleteInvoice (data) {
  return request({
    url: '/ooh-scp/v1/invoice/deleteinvoice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取特定开票信息详情
export function getInvoice (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getinvoice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取某次开票申请中已开出的发票列表
export function getInvoiceApplicationList (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getinvoiceapplicationlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取特定 合同/结算单/换位单 的开票信息
export function getInvoiceList (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getinvoicelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 条件查询开票申请，分页显示
export function getInvoicePage (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getinvoicepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更新开票
export function updateInvoice (data) {
  return request({
    url: '/ooh-scp/v1/invoice/updateinvoice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询：合同/结算单 开票信息
export function getBusinessPage (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getbusinesspage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询：合同开票的汇总信息
export function getinvoicesummary (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getinvoicesummary',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 作废某张票据
export function invalidInvoice (data) {
  return request({
    url: '/ooh-scp/v1/invoice/invalidinvoice',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询：查询发票的汇总信息
export function getinvoicesummaryv2 (data) {
  return request({
    url: '/ooh-scp/v1/invoice/getinvoicesummaryv2',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取发票导入记录
export function getinvoiceimportspage (data) {
  return request({
    url: '/ooh-scp/v1/invoiceimports/getinvoiceimportspage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据公司全称查组织机构代码
export function queryorgcodebycompanyfullname (data) {
  return request({
    url: '/ooh-scp/v1/invoice/queryorgcodebycompanyfullname',
    method: 'post',
    data: qs.stringify(data)
  })
}
