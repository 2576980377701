import request from '@/utils/requestV2'
const qs = require('qs')

export function getProvinceList () {
  return request({
    url: '/ooh-sys/v1/provincecityregion/getprovincelist',
    method: 'post',
    data: ''
  })
}
export function getCityListByProvinceCode (data) {
  return request({
    url: '/ooh-sys/v1/provincecityregion/getcitylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
export function getRegionListByCityCode (data) {
  return request({
    url: '/ooh-sys/v1/provincecityregion/getregionlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 根据城市code获取城市
 */
export function getCity (data) {
  return request({
    url: '/ooh-sys/v1/provincecityregion/getcity',
    method: 'post',
    data: qs.stringify(data)
  })
}
