<template>
  <div>
    <Modal v-model="show" footer-hide>
      <p slot="header" class="text-center">租户录入</p>
      <Form ref="formData" :model="formData" :rules="formRules" label-position="top">
        <FormItem label="租户类型" prop="type">
          <i-select v-model="formData.type" size="small" placeholder="请选择租户类型">
            <i-option v-for="item in types" :key="'t_' + item.id" :value="item.id">{{ item.name }}</i-option>
          </i-select>
        </FormItem>
        <FormItem :label="formData.type === 9 ? '承租人' : '租户名称'" prop="name">
          <i-input size="small" v-model.trim="formData.name" />
        </FormItem>
        <FormItem v-if="formData.type === 10" label="法人代表" prop="legalPerson">
          <i-input size="small" v-model.trim="formData.legalPerson" />
        </FormItem>
        <FormItem :label="formData.type === 9 ? '身份证号' : '统一信用代码'" prop="orgCode">
          <Row :gutter="8">
            <i-col :span="formData.type === 9 ? 24 : 18">
              <i-input size="small" v-model.trim="formData.orgCode" />
            </i-col>
            <i-col v-if="formData.type === 10" span="6">
              <i-button type="success" size="small" @click="autoInsertOrgcode">自动录入</i-button>
            </i-col>
          </Row>
        </FormItem>
        <FormItem label="联系电话" prop="tel">
          <i-input size="small" v-model.trim="formData.tel" />
        </FormItem>
        <FormItem label="详细地址">
          <Row :gutter="8" class="m-b-5">
            <i-col span="8">
              <Select size="small" v-model="formData.provinceCode" placeholder="省" @on-change="changeProvice">
                <i-option v-for="item in proviceArray" :key="'provice_' + item.code" :value="item.code">{{ item.name
                }}</i-option>
              </Select>
            </i-col>
            <i-col span="8">
              <Select size="small" v-model="formData.cityCode" placeholder="市" @on-change="changeCity">
                <i-option v-for="item in cityArray" :key="'city_' + item.code" :value="item.code">{{ item.name
                }}</i-option>
              </Select>
            </i-col>
            <i-col span="8">
              <Select size="small" v-model="formData.areaCode" placeholder="区">
                <i-option v-for="item in areaArray" :key="'area_' + item.code" :value="item.code">{{ item.name
                }}</i-option>
              </Select>
            </i-col>
          </Row>
          <i-input size="small" v-model.trim="formData.address" placeholder="详细地址" />
        </FormItem>
        <FormItem label="备注">
          <i-input size="small" v-model.trim="formData.description" />
        </FormItem>
        <FormItem class="text-right m-t-20">
          <Button type="text" class="m-r-5" @click="handleCancel">取消</Button>
          <Button type="primary" :loading="submitLoading" @click="handleSubmit">确认录入</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import { getProvinceList, getCityListByProvinceCode, getRegionListByCityCode } from '@/api/sys/provincecityregion'
import { queryorgcodebycompanyfullname } from '@/api/scp/invoice'
import { addBusiness } from '@/api/crm/advertiser'
const getFormData = () => {
  return {
    type: null,
    name: '',
    address: '',
    areaCode: '',
    cityCode: '',
    description: '',
    legalPerson: '',
    orgCode: '',
    provinceCode: '',
    tel: ''
  }
}
export default {
  props: {
    typeArray: {
      type: Array,
      default: new Array(0)
    },
    getListData: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      show: false,
      types: [],
      formData: getFormData(),
      formRules: {
        type: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        legalPerson: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        orgCode: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)|([^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$)/, message: ' ' }
        ],
        tel: [
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: ' ' }]
      },
      proviceArray: [],
      cityArray: [],
      areaArray: [],
      submitLoading: false
    }
  },
  methods: {
    showModal () {
      this.types = this.typeArray
      this.formData.type = this.types[0].id
      this.getProvinceData()
      this.show = true
    },
    getProvinceData () {
      getProvinceList().then(res => {
        if (res && !res.errcode) {
          this.proviceArray = res
        } else {
          this.proviceArray = []
        }
      })
    },
    changeProvice () {
      this.getCityData()
    },
    getCityData () {
      getCityListByProvinceCode({ provinceCode: this.formData.provinceCode }).then(res => {
        if (res && !res.errcode) {
          this.cityArray = res
        } else {
          this.cityArray = []
        }
      })
    },
    changeCity () {
      this.getAreaData()
    },
    getAreaData () {
      getRegionListByCityCode({ cityCode: this.formData.cityCode }).then(res => {
        if (res && !res.errcode) {
          this.areaArray = res
        } else {
          this.areaArray = []
        }
      })
    },
    autoInsertOrgcode () {
      if (!this.formData.name) {
        this.$Notice.error({ title: '操作提示', desc: '请输入公司名称' })
        return false
      }
      queryorgcodebycompanyfullname({ companyName: this.formData.name }).then(res => {
        if (res && !res.errcode) {
          this.formData.orgCode = res.organizationCode
        } else {
          this.$Notice.error({ title: '操作提示', desc: '未检索到社会统一信息代码，请确认公司名称是否正确或手动输入社会统一信息代码' })
        }
      })
    },
    handleCancel () {
      this.show = false
      this.$refs.formData.resetFields()
    },
    handleSubmit () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.submitLoading = true
          addBusiness(this.formData).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ title: '操作提示', desc: '操作成功' })
              this.getListData()
              this.$refs.formData.resetFields()
              this.show = false
            }
          }).finally(() => { this.submitLoading = false })
        }
      })
    }
  }
}
</script>
